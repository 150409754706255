import { importReportImage } from "../../../utils/imageImporters";

export const expenseTracker = (
  <>
    <div className="report-section">
      <h2 className="accent">Expense Tracker</h2>
      <p>
        The app stores transactions, budgets, and categories created by the user, and then 
        displays charts based on the data. The images in this report have example data for 
        a yearly personal budget.
      </p>
      <p>
        All the data is saved to local storage with React Redux 
        Persist, which means you are free to use the app as much as you want since the data is 
        saved locally.
      </p>
    </div>
    
    <div className="report-section">
      <h3>Category manager</h3>
      <img src={importReportImage('category-manager.png')} alt="category manager"/>
      <p>
        The category manager is used to create a set of categories that are used when 
        adding transactions and budgets. The categories can be edited or deleted.
      </p>
    </div>
  
    <div className="report-section">
      <h3>Transaction form</h3>
      <img src={importReportImage('add-transaction.png')} alt="transaction form"/>
      <p>
        The transaction form allows the user to add new transactions in a category.
      </p>
    </div>

    <div className="report-section">
      <h3>Transaction list</h3>
      <img src={importReportImage('transaction-list.png')} alt="transaction list"/>
      <p>
        The transaction list shows all the transactions added to the app. They can be deleted 
        or edited.
      </p>
    </div>

    <div className="report-section">
      <h3>Budget tracker</h3>
      <img src={importReportImage('budget-tracker.png')} alt="budget tracker"/>
      <p>
        The budget tracker allows the user to create, edit or delete budgets. The above 
        image has example data for a personal yearly budget.
      </p>
    </div>

    <div className="report-section">
      <h3>The charts</h3>
      <p>
        The app displays 5 charts to visualize the data. (You can open the images in a new tab to get a better look.)
      </p>
      <img src={importReportImage('income-vs-expense.png')} alt="income vs expense"/>
      <img src={importReportImage('category-spending.png')} alt="spending by category"/>
      <img src={importReportImage('monthly-income-and-expense-trend.png')} alt="monthly income and expense trend"/>
      <img src={importReportImage('budget-vs-actual-spending.png')} alt="budget vs actual spending"/>
      <img src={importReportImage('budget-utilization.png')} alt="budget utilization"/>
    </div>

    {/* <div className="report-section">
      <h3>Thoughts</h3>
      <p>
        I created this project to mostly improve my TypeScript, Redux, and Tailwind skills.
      </p>
    </div> */}
  </>
);
