
export const importBackgroundImage = (fileName) => {
  try {
    return require(`../assets/images/bg-images/${fileName}`);
  } catch {
    console.error(`Image ${fileName} not found`);
    return null;
  }
}

export const importReportImage = (fileName) => {
  try {
    return require(`../assets/images/report/${fileName}`);
  } catch {
    console.error(`Image ${fileName} not found`);
    return null;
  }
}

export const importImage = (fileName) => {
  try {
    return require(`../assets/images/${fileName}`);
  } catch {
    console.error(`Image ${fileName} not found`);
    return null;
  }
}

export const importIcon = (fileName) => {
  try {
    return require(`../assets/icons/${fileName}`);
  } catch {
    console.error(`Image ${fileName} not found`);
    return null;
  }
}

export const importLogo = (fileName) => {
  try {
    // Dynamically import the image based on the unit name
    return require(`../assets/logos/${fileName}`);
  } catch (error) {
    // Handle error if image not found
    console.error(`Image ${fileName} not found`);
    return null;
  }
};