import { NavLink } from 'react-router-dom';
import './Home.css';
import AboutMe from '../../components/AboutMe';
// import ShowcaseSection from '../../components/ShowcaseSection';
import { ecommerceReport } from '../../components/ShowcaseSection/reports/ecommerce';
import FlipPanel from '../../components/FlipPanel';
import { magicianReport } from '../../components/ShowcaseSection/reports/magician';
import { carShopReport } from '../../components/ShowcaseSection/reports/carshop';
import { useEffect, useState } from 'react';
import ProjectsIntro from '../../components/ProjectsIntro';
import { importImage } from '../../utils/imageImporters';
import Contact from '../../components/Contact';
import SmallerProjects from '../../components/SmallerProjects';
import { expenseTracker } from '../../components/ShowcaseSection/reports/expenseTracker';

const Home = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const NavBar = () => {
    return (
      <div className='navbar'>
        <div>
          {/* logo */}
        </div>

        <div className='navbar-links'>
          <a href="#about-me">About me</a>
          <a href="#projects">Projects</a>
          <a href='https://github.com/krilevo' target='_blank' rel='noreferrer'>GitHub</a>
          <a href="https://www.linkedin.com/in/kristianlevola/" target='_blank' rel='noreferrer'>LinkedIn</a>
        </div>
      </div>
    );
  };

  const MobileNavBar = () => {
    const [isActive, setIsActive] = useState(false);

    const closeMenu = () => {
      setIsActive(false)
    };

    return (
      <div className='mobile-navbar'>
        <div>
          {/* logo */}
        </div>

        {/* <button className='toggle-nav-menu' onClick={() => setIsActive(!isActive)}>Menu</button> */}
        <div className='toggle-nav-menu' onClick={() => setIsActive(!isActive)}></div>


        <div className={`mobile-navbar-links ${isActive ? 'mobile-navbar-active' : ''}`}>
          <button className='mobile-nav-menu-close' onClick={closeMenu}></button>
          <a href="#about-me" onClick={closeMenu} className="bebas-neue-regular">About me</a>
          <a href="#projects" onClick={closeMenu} className="bebas-neue-regular">Projects</a>
          <a href='https://github.com/krilevo' target='_blank' rel='noreferrer'>GitHub</a>
          <a href="https://www.linkedin.com/in/kristianlevola/" target='_blank' rel='noreferrer'>LinkedIn</a>
        </div>
      </div>
    );
  };

  const Header = () => {
    return (
      <div className='header'>
        {isMobile ? <MobileNavBar /> : <NavBar />}

        <div className="header-content">
          <div className='header-content-container'>
            <h1>Hey! I am <span>Kristian</span>, <br/> a Web Developer.</h1>
            <img src={importImage('bio.jpg')} alt='profile'/>
          </div>
        </div>
      </div>
    );
  };

  const report = {
    name: 'Ecommerce',
    sections: [
      {
        title: "Ecommerce",
        description: `The second ecommerce I have created. The first was done as a mandatory group 
        school project (3 people) and was a barely working bowl of spaghetti code 
        (it still received a 3/5 rating). 

        I did this project to improve my skills and to create a portfolio project to show my 
        skills. I tried focusing on producing good quality, bugless, working code, and 
        scalability and responsiviness was kept in mind, as was security.

        There were 3 notably difficult and complex things in the project: category navigation, 
        authentication, and the cart/checkout.`,
      },
      {
        title: "About the example data",
        description: `The images are mainly from <a class="link" href="https://unsplash.com/">Unsplash</a>. It was somewhat hard to find good product
        images, so some of them look pretty bad and were obviously not meant for ecommerce. 
        Ignore them.
        <br></br>
        I have added example products only to <strong>electronics</strong>,
        <strong>household appliances</strong>, and <strong>cameras</strong>.`,
        imageUrl: "./assets/gifs/nav-card-demo.gif",
        imageAlt: "Navigation Card Demo"
      },
      // Add more sections as needed
    ]
  };
  

  return (
    <div className='home'>
      <Header />
      <AboutMe />
      {/* <ShowcaseSection
        title="Ecommerce"
        description="I developed this project to showcase my full-stack proficiency, with a primary focus on the technical aspects. The visual design is nothing special. On the website users can create an account, browse products, add products to cart and buy them. There is also an admin panel in which admins can manage users, categories, products, and orders."
        techStack="MongoDB, ExpressJS, NodeJS, ReactJS"
        projectLink="https://ecommerce.kristianlevola.com/"
        githubLink="https://github.com/yourusername/yourproject"
        imageSource="./assets/logos/github.svg"
        report={ecommerceReport}
        // onClick={() => showReport(0)}
      /> */}

      <ProjectsIntro/>
      
      <FlipPanel
        bgImage='ecommerce.png'
        id='ecommerce-section'
        title="Ecommerce"
        description="I developed this project to showcase my full-stack proficiency, 
                      with a primary focus on the technical aspects. On the website users can create an 
                      account, browse products, add products to cart and buy them. 
                      There is also an admin panel in which admins can manage users, 
                      categories, products, and orders."
        techStack="ReactJS, CSS, NodeJS, ExpressJS, MongoDB"
        projectLink="https://ecommerce.kristianlevola.com/"
        githubLink="https://github.com/krilevo/ecommerce-public"
        report={ecommerceReport}
      />
      
      <FlipPanel
        bgImage='magician.png'
        title="Magician website"
        id='magician-section'
        description="A website for a real client. The owner can add and delete images & videos on the website. 
                      The frontpage works as a landing page and has a form that allows users to contact the owner. 
                      The owner receives the form data via email. Also has a pretty cool navigation menu."
        techStack="ReactJS, CSS, NodeJS, ExpressJS, MongoDB"
        projectLink="https://www.taikuritaavisaarva.com/"
        githubLink={null}
        report={magicianReport}
      />

      {/* <FlipPanel
        bgImage='carshop.png'
        title="Car dealership"
        id='car-dealership-section'
        description="A smaller website I created to improve my designing skills and CSS. 
                      It does not have a backend, but instead the mock data (cars) is in a JSON file."
        techStack="HTML, CSS, JavaScript"
        projectLink="http://car-dealership-2024.s3-website.eu-central-1.amazonaws.com/"
        githubLink="https://github.com/krilevo/car-dealership"
        report={carShopReport}
      /> */}

      <FlipPanel
        bgImage='expense-tracker.png'
        title="Expense Tracker"
        id='expense-tracker-section'
        description="An app for tracking expenses and budgeting. Uses Recharts for charts, 
                      and Redux for state management."
        techStack="ReactJS, React Redux, TypeScript, Tailwind CSS"
        projectLink="https://expense-tracker.kristianlevola.com/"
        githubLink="https://github.com/krilevo/expense-tracker"
        report={expenseTracker}
      />

      <SmallerProjects/>
      <Contact/>
    </div>
  );
};

export default Home;