import './Contact.css';

const Contact = () => {

  return (
    <div className="contact-section">
      <h2>Contact Me</h2>
      <p>
        You can contact me on LinkedIn.
      </p>
      <a href='https://www.linkedin.com/in/kristianlevola/' target="_blank">Here is a link to my profile.</a>
    </div>
  );
};

export default Contact;