import { importReportImage } from "../../../utils/imageImporters";

export const carShopReport = (
  <>
    <div className="report-section">
      <h2 className="accent">Car dealership</h2>
      <h3>Car Slider</h3>
      <img src={importReportImage('car-slider.png')} alt="car slider"/>
      <p>
        There are sliders that display cars. When moving the slider, it snaps to 
        the nearest car using <strong>scroll-snap-type.</strong>
      </p>
      <img src={importReportImage('slider-snap.png')} alt="slider snap"/>
    </div>
    
    <div className="report-section">
      <h3>CSS Counter</h3>
      <img src={importReportImage('choose-us.png')} alt="choose us"/>
      <p>
        This section could have been easily made with an ordered list element, but I wanted 
        to try something new, so I used <strong>CSS counter</strong>. They are basically variables, 
        that can count how many times an element is used. I used it to count the 
        p elements.
      </p>
      <img src={importReportImage('choose-us-html.png')} alt="choose us html"/>
      <img src={importReportImage('choose-us-css.png')} alt="choose us css"/>
    </div>
  
    <div className="report-section">
      <h3>Price range slider</h3>
      <img src={importReportImage('price-range.png')} alt="price range"/>
      <p>
        This was more difficult than I expected. HTML doesn't actually allow having 
        two selectors/thumbs in the same slider, which means that to create a slider like in 
        the above image, you have to put two range inputs on top of each other. The 
        slider track also is not actually an input element, but a div that has 
        linear-gradient background. The gradient is adjusted based on the values of 
        the two thumbs.
      </p>

      <img src={importReportImage('price-range-slider-html.png')} alt="price range slider html"/>
      
      <p>The functionality of course is created with Javascript. You can find the full code on 
        my <a className="link" href="https://codepen.io/krilevo/pen/xxodboW" target="_blank">CodePen</a>.
      </p>
      <p>
        An important thing to note is that browsers have somewhat inconsistent styles 
        regarding range input elements.
      </p>
    </div>

    <div className="report-section">
      <h3>Custom selectors</h3>
      <img src={importReportImage('custom-select.png')} alt="custom select"/>
      <p>
        The cars can be filtered with custom made selectors. To create the selectors I 
        added a span element to create a custom made arrow. The important thing is to 
        add <strong>pointer-events: none</strong> to the custom arrow to allow the 
        selector to work from underneath the custom arrow.
      </p>
      <img src={importReportImage('pointer-events-none.png')} alt="pointer events none"/>
    </div>

    <div className="report-section">
      <h3>Calc() trick</h3>
      <img src={importReportImage('calc-trick.png')} alt="calc trick"/>
      <p>
        On the article page the content has a max-width of 1000px, but the image 
        is the width of the entire viewport. This can be achieved with a certain 
        <strong> calc() trick </strong>
        which is shown in the image below. It is important to also add the 
        <strong> overflow-x: hidden </strong>
        to the body, because otherwise it will create horizontal scrolling. 
      </p>
      <img src={importReportImage('calc-trick-code.png')} alt="calc trick code"/>
    </div>

    <div className="report-section">
      <h3>Thoughts</h3>
      <p>Like I said, this is merely the frontend of a website that I created to test
          out some tricks and to improve my skills. The look/style of the website is 
          quite bland to be honest. Web design is not really my strength, and I still 
          try to improve my designing skills even though I have no particular interest 
          in web designing. In fact, my designing skills have already vastly improved 
          since when I started web development.
      </p>
    </div>
  </>
);
