import { importLogo } from '../../utils/imageImporters';
import './SmallerProjects.css';

const SmallerProjects = () => {

  const projects = [
    {
      name: "TicTacToe",
      description: "A TicTacToe game with a CPU opponent.",
      gitHub: "https://github.com/krilevo/tictactoe-java",
      techStack: ['java']
    },
    {
      name: "Weather app",
      description: "A weather app that uses the Open-Meteo API.",
      gitHub: "https://github.com/krilevo/weather-app-java",
      techStack: ['java']
    },
    {
      name: "Regex engine",
      description: "A javascript regex engine that supports basic regular expression functionality.",
      gitHub: "https://github.com/krilevo/regex-engine-js",
      techStack: ['javascript']
    },
    {
      name: "Mobile To-Do app",
      description: "Made with React Native, TypeScript and Expo.",
      gitHub: "",
      techStack: ['react', 'typescript', 'expo']
    }
  ]

  const ProjectCard = ({ project }) => {
    return (
      <div className='smaller-project-card'>
        <h3>{project.name}</h3>
        <p>{project.description}</p>
        <div className='smaller-project-card-tech-container'>
          {project.techStack.map((tech, index) => (
            <img key={index} src={importLogo(`${tech}.png`)} alt={`${tech} logo`}/>
            ))}
        </div>
        <a href={project.gitHub} target="_blank" rel="noopener noreferrer"><img src={importLogo('github.png')}/></a>
      </div>
    );
  };

  const projectsJSX = (projects) => {
    return (
      projects.map((project, index) => (
        <ProjectCard key={index} project={project} />
      ))
    );
  };

  return (
    <div className='smaller-projects'>
      <div>
        <h2>Other projects</h2>
      </div>
      <div className='smaller-project-card-container'>
        {projectsJSX(projects)}
      </div>
    </div>
  )
}

export default SmallerProjects;