import React from 'react';
import './Footer.css';

const Footer = () => {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <footer className='footer'>
      <div id="footer-copyright">
        &copy; <span id="current-year">{getCurrentYear()}</span> Kristian Levola. All rights reserved.
      </div>
    </footer>
  );
};

export default Footer;
