import { importReportImage } from "../../../utils/imageImporters";

export const ecommerceReport = (
  <>
    <div className="report-section">
      <h2 className="accent">Ecommerce</h2>
        <p>
          The second ecommerce I have created. The first was done as a group 
          school project (3 people) and had some issues, though
          it still received a 3/5 rating. 

          I did this project to improve my skills and to create a portfolio project to demonstrate my 
          skills. My focus was on producing modular, clean code with an emphasis on scalability, 
          responsiveness, and security.

          There were three somewhat difficult and complex aspects of the project: 
          category navigation, authentication, and the cart/checkout functionality.
        </p>
    </div>
    
    <div className="report-section">
      <h3 className="accent">About the example data</h3>
      <p>
        The images are mainly from <a className="link" href="https://unsplash.com/" target="_blank">Unsplash</a>. It was somewhat hard to find good product
        images, so some of them look pretty bad and were obviously not meant for ecommerce. 
        <br></br>
        I have added example products only to <strong>electronics</strong>,
        <strong> household appliances</strong>, and <strong>cameras</strong>.
      </p>
    </div>

    <div className="report-section">
      <h3 className="accent">Product sliders</h3>
      <img className='report-img' src={importReportImage("product-slider.png")} alt="product slider"/>
      <p>
        On the frontpage there are two product sliders, one for best selling products and one for 
        discounted products. Both fetch 10 products max that fit the criteria.
      </p>
    </div>
    
    <div className="report-section">
      <h3 className="accent">Category Navigation</h3>
      <p>
        What made this diffiuclt was the fact that I made it scalable. The admin can create 
        and delete categories on the admin panel. This meant I had to consider how to handle 
        the relationships between parent- and sub categories, and how to save them in 
        the database. 
  
        In a real-life project, the categories should have probably been hard-coded in the 
        frontend, because addding and deleting 
        categories is such a rare task that making it dynamic would have been a waste of time. 
        However, I wanted to make it dynamic as an additional challenge.
      </p>

      <img src={importReportImage('category-navigation.png')}/>
    </div>

    <div className="report-section">
      <h3 className="accent">Category Page</h3>
      <img className='report-img' src={importReportImage("category-page.png")} alt="category page"/>
      <p>
        The category page, of course, shows all the products from the selected category. 
        The products can be sorted by least expensive, most expensive, and most sold. 
        There are 10 products per page. If a product image cannot be found from AWS S3, 
        then a placeholder image is used.
      </p>
    </div>

    <div className="report-section">
      <h3 className="accent">Creating an account</h3>
      <img className='report-img' src={importReportImage("create-user.png")} alt="create user form"/>
      <p>
        When creating an account the user has to give first name, last name, email, and 
        write a password twice. There is no enforcement to create a strong password, altough 
        in a real ecommerce it would be ideal. The user is also not sent email after creating 
        an account so feel free to create an account with a fake email to test the site.
        If you want to test out the admin panel, log in with email: john.doe@gmail.com 
        and password: 1234.
      </p>

      <p>
        Right now there is no functionality to delete an account, except from the admin 
        panel. 
      </p>
    </div>
    
    <div className="report-section">
      <h3 className="accent">Authentication</h3>
      <p>
        This isn't the first time I have created an authentication system, however 
        I have realized that in previous attempts the security wasn't entirely robust. 
        This time I wanted to make sure it is done right. Only admins can 
        enter the admin side of the website, and all important HTTP requests 
        also send <strong>a bearer token</strong> to the backend. In the backend, an authentication 
        middleware that checks if the user is an admin. 
        The tokens have an expiration time, and passwords are stored to the database after 
        hashing them with salt rounds.
      </p>

      <img src={importReportImage('bearer-token-code.png')}/>
    </div>
    
    <div className="report-section">
      <h3 className="accent">Cart & Checkout</h3>
      <img className='report-img' src={importReportImage("cart.png")} alt="cart"/>
      <p>
        In the cart are listed all the products and their quantity. The quantity 
        of a product can be increased and decreased. The total cost is also shown. 
        All the products are saved in local storage.
      </p>
      <p>
        The user needs to give personal details and choose a delivery and a payment 
        method. 
      </p>
      <img className='report-img' src={importReportImage("confirm-order.png")} alt="confirm order"/>
      <p>
        Lastly users have to confirm the order.  If the user is logged in, there is a checkbox for the user 
        to save the personal details when confirming the order. The user is NOT informed with an 
        email about the purchase. However, I have created such systems before and may add 
        this feature later in the project.
      </p>
    </div>

    <div className="report-section">
      <h3 className="accent">Search</h3>
      <img className='report-img' src={importReportImage("search.png")} alt="search functionality"/>
      <p>
        This was my first time creating a search functionality. It is not very sophisticated, 
        it simply fetches the products with matching name. I also immediately noticed that 
        fetching new data after every single change in the search was an issue. The solution 
        was to create a <strong>debounce</strong>, which is something I had not done before either.
      </p>
    </div>

    <div className="report-section">
      <h3 className="accent">Admin</h3>
      <img className='report-img' src={importReportImage("admin.png")} alt="admin panel"/>
      <p>
        The admins can manage users, products, and categories in the admin panel. They 
        can also delete products on the category page and edit product details on the 
        product page.
      </p>
    </div>

    <div className="report-section">
      <h3 className="accent">Backend</h3>
      <img className='report-img' src={importReportImage("ecommerce-backend.png")} alt="ecommerce backend"/>
      <p>
        The backend is built with Node.js and Express.js. The main functionality is organized 
        into routes, controllers, and models. The routes files contain all the relevant routes 
        for different functionalities. The controller files handle the core logic, while the
        model files define the Mongoose schemas.
      </p>

      <img className='report-img' src={importReportImage("ecommerce-routes.png")} alt="ecommerce routes"/>
      
      <img className='report-img' src={importReportImage("ecommerce-controllers.png")} alt="ecommerce controllers"/>
      
    </div>
    
    <div className="report-section">
      <h3 className="accent">Problems & challenges</h3>
          <p>
            The structure of categories proved to be a challenge. I could have made 
            the categoy tree static, but I wanted to allow the admins to add and 
            delete categories, which made building the structure much more 
            challenging. The solution was to add a full path property to each 
            category. For example the full path of 'smartphones' is 
            'electronics/phones/smartphones'.
          </p>

          <p>
            I had difficulties getting the backend to work because the frontend 
            is on HTTPS, which required the backend to be as well. For that, I 
            needed an SSL certificate. To obtain the certificate, I installed 
            Certbot on the Amazon Linux EC2 instance. This wasn't easy; the first 
            few attempts failed, but eventually, I succeeded. Next, I had to 
            allow access to the instance through port 80. To do that, I 
            configured the Security Group but encountered issues. After some time, I
            realized I had configured the wrong security Group. AWS automatically
            created two Security Groups for the instance: <strong>default</strong>, and 
            <strong> launch-wizard-1</strong>. Configuring launch-wizard-1 solved the issue.
            Finally, I created the 
            certificate with Certbot, and after facing some more difficulties, I finally
            successfully established a HTTPS connection to the backend.
          </p>
    </div>
    

    <div className="report-section">
      <h3 className="accent">Issues</h3>
      <p>
        I added a functionality that checks whether a product image exists 
        in AWS S3. If it doesn't exist, then a placeholder image should be used.
        It seems to work, however the console gets several CORS errors for 
        some reason. 
        <br/>
        UPDATE: Clearing the cache fixed this.
      </p>
    </div>
    
    <div className="report-section">
      <h3 className="accent">How to improve</h3>
      <ul>
        <li>
          The list of products by category could use smaller images than the 
          product page to improve loading times.
        </li>
        <li>A 404 page should be added.</li>
        <li>Users should be able to edit and delete their account.</li>
        <li>Add 'related products' section or something similar to product page.</li>
        <li>Users should be able to review products.</li>
      </ul>
    </div>

    <div className="report-section">
      <h3 className="accent">Thoughts</h3>
      <p>
        I improved a lot during this projects and if I had to do this again I would 
        do some things differently. I also think that I should have spent more time 
        planning this project to anticipate problems and the length of the project. 
        I have now also started to pay more attention to modularity.
      </p>
    </div>
  </>
)
