import { useEffect, useState } from 'react';
import './FlipPanel.css';
import { importBackgroundImage, importIcon, importLogo } from '../../utils/imageImporters';

const FlipPanel = ({ bgImage, id, title, description, techStack, projectLink, githubLink, report }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  useEffect(() => {
    // Add overflow hidden to body when panel is flipped
    if (isFlipped) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    // Cleanup function to remove overflow hidden when component unmounts or panel is flipped back
    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [isFlipped]);

  const sectionStyle = {
    backgroundImage: `url(${importBackgroundImage(bgImage)})`,
  };

  return (
    <div id={id} className={`flip-panel ${isFlipped ? 'flipped' : ''}`}>
      <div className='front' style={sectionStyle}>
        <div className='showcase-section-intro'>
          <h3>{title}</h3>
          <p> 
            {description} 
          </p>
          <p><strong>Tech stack: </strong>{techStack}</p>
          <div className='showcase-section-intro-actions'>
            <a className="project-link" href={projectLink} rel="noreferrer" target="_blank">Check it out</a>
            {githubLink
            ?
            <a className='github-link' href={githubLink} rel="noreferrer" target='_blank'><img src={importLogo('github.svg')} alt="GitHub Logo" /></a>
            :
            <span className='github-link code-private'><img src={importLogo('github.svg')} alt="GitHub Logo" /></span>
            }
            
            {/* <button className='flip-btn' onClick={handleFlip}>
              <a href={`#${title}`}>Read more</a>
            </button> */}
            <a href={`#${title}`}>
              <button className='flip-btn' onClick={handleFlip}>Read more</button>
            </a>
          </div>
        </div>
      </div>

      <div id={title} className='back '>
        <button className='flip-back-btn' onClick={handleFlip}>
          <img src={importIcon('back.svg')} alt='back button'/>
        </button>
        <div className='back-content'>
          {report}
        </div>
      </div>
    </div>
  );
};

export default FlipPanel;