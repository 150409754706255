import { importReportImage } from "../../../utils/imageImporters"


export const magicianReport = (
  <>
    <div className="report-section">
      <h2 className="accent">Magician website</h2>
      <p>
        The third time I have actually made this website. The first one was part of my internship while 
        studying for a Bachelor of Business Administration, 
        however there were issues with the hosting costs of the backend, so I created a temporary 
        Wordpress website until I could resolve the hosting costs. The solution was to create the 
        backend with Lambda functions.
      </p>
    </div>

    <div className="report-section">
      <h3 className="accent">The Lambda functions</h3>
      <p>
        I had no prior experience with Lambda functions, and the AWS Lambda service turned out 
        to be more troublesome than I expected. I had to create zip folders and upload them 
        repeatedly to debug the code. I'm sure there are easier ways to do it, but I didn't 
        bother figuring it out since the backend's scale was relatively small. Next time, 
        I'll definitely figure out how to do it locally.
      </p>
    </div>
    
    <div className="report-section">
      <h3 className="accent">The navigation card</h3>
      <img className="gif" src={importReportImage('nav-card-demo.gif')} alt="nav-card-demo"/>
      <p>
        The navigation menu is inside a playing card, which fits the magician theme 
        of the website.
      </p>

    </div>
    
    <div className="report-section">
      <img className='report-img' src={importReportImage("magician-form.png")} alt="magician form"/>

      <h3 className="accent">The contact form</h3>
      <p>
        The contact form allows users to request for a gig. The information in the form is sent to the 
        owners email inbox using AWS Lambda with AWS SES. The form data is also saved to a database.
      </p>
      <p>
        After switching to the temporary wordpress website, there was an influx of bots spamming 
        the contact form with junk or people advertising their SEO services and such. To avoid spam in the future, 
        I added a reCAPTCHA to the current, ReactJS based website.
      </p>
    </div>
    
    <div className="report-section">
      <h3 className="accent">Problems & challenges</h3>
      <p>
        The first version had an ExpressJS backend hosted on AWS EC2. I was new to AWS back then, and EC2 
        was very confusing to me. I managed to get it working, but the hosting costs were insanely high (likely because of elastic load balancer). 
        Changing the backend to Lambda functions seems to have reduced the costs by 95-99%, at least 
        based on the current data. It might change in the future, because I am unsure whether there is 
        a 'free tier' applied to the AWS Lambda service.
      </p>
      <p>
        Another problem arose when I tried to upload videos to the website. To send data to AWS Lambda, 
        it needs to go through API Gateway, which seems to have a payload limit of 10MB(?). 
        The solution was to create an AWS S3 promise in the Lambda, and then upload the video to S3 
        directly from the frontend.
      </p>
    </div>
  </>
)