import { useEffect } from 'react';
import './AboutMe.css';
import { importLogo } from '../../utils/imageImporters';

const AboutMe = () => {

  useEffect(() => {
    const slider = document.querySelector('.slider');

    const slides = document.querySelectorAll('.slide');
    slides.forEach(slide => {
    slider.appendChild(slide.cloneNode(true));
  });
  }, [])
  
  

  const InfiniteSlider = () => {
    return (
      <div className="slider-container">
        <div className="slider">
          <div className="slide"><img src={importLogo('html5.png')} alt="HTML logo" className="logo"/></div>
          <div className="slide"><img src={importLogo('css3.png')} alt="CSS logo" className="logo"/></div>
          <div className="slide"><img src={importLogo('sass.png')} alt="Sass logo" className="logo"/></div>
          <div className="slide"><img src={importLogo('javascript.png')} alt="JS logo" className="logo"/></div>
          <div className="slide"><img src={importLogo('nodejs.png')} alt="Node-js logo" className="logo"/></div>
          <div className="slide"><img src={importLogo('react.png')} alt="React logo" className="logo"/></div>
        </div>
      </div>
    );
  };

  return (
    <section id='about-me'>
      <h2 className="bebas-neue-regular">About me</h2>
      <div className="box">
        <div className="text-container">
          <p>
            I completed a Bachelor's degree in 
            Business Information Systems (BBA) in Tampere University of Applied Sciences (TAMK) 
            (2018-2022), specializing in web services. In all 
            the coding school projects I was part of, I always had a full-stack role. 
            However, I prefer frontend and my go-to framework is ReactJS.
          </p>

          <p>
            I like learning, which is why I read non-fiction books. I also like to
            code personal projects on my free time. Every summer I also go fishing with 
            my friend whenever we have time for that.
          </p>

          <p>
            When it comes to technical skills, I am most proficient in HTML, CSS/Sass, 
            JavaScript, and ReactJS. My primary backend language is Node.js. For databases, I 
            typically work with MongoDB, although I'm also familiar with SQL.
          </p>

          <p>
            I use AWS for hosting and for other services. In my projects I have utilized S3, Amplify, EC2, 
            Lambda, API Gateway, Route 53, Lightsail, and Amazon SES (Simple Email Service). 
          </p>
        </div>
      </div>

    <InfiniteSlider />
    </section>
  );
};

export default AboutMe;