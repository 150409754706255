import './ProjectsIntro.css';

const ProjectsIntro = () => {

  return (
    <div id="projects">
      <h2>Showcase projects</h2>
      <p>
        Below are three projects I have created to showcase my skills. They are an 
        <a href="#ecommerce-section"> ecommerce</a>, 
        <a href="#magician-section"> a website for a magician</a>, and
        <a href='#expense-tracker-section'> an expense tracker</a>
        {/* <a href="#car-dealership-section"> car dealership</a>. */}
      </p>
      <p>
        I also have some stuff on 
        <a href="https://codepen.io/krilevo" target='_blank' rel='noreferrer'> CodePen</a>
        .
      </p>
    </div>
  );
};

export default ProjectsIntro;